<template>
  <div style="padding: 20px; max-width: 800px; margin: auto">
    <h1>How to Delete Your Account</h1>
    <p>You can delete your account by following these steps in our app:</p>
    <ol>
      <li>Open the app.</li>
      <li>Navigate to <b>More</b> > <b>Profile</b>.</li>
      <li>Select <b>Delete My Account</b>.</li>
      <li>Confirm the deletion by following the prompts.</li>
    </ol>
    <v-row>
      <v-col cols="3">
        <v-img src="/assets/images/deleteAcc1.png" style="height: auto"></v-img>
      </v-col>
      <v-col cols="3">
        <v-img src="/assets/images/deleteAcc2.png" style="height: auto"></v-img>
      </v-col>
      <v-col cols="3">
        <v-img src="/assets/images/deleteAcc3.png" style="height: auto"></v-img>
      </v-col>
      <v-col cols="3">
        <v-img src="/assets/images/deleteAcc4.png" style="height: auto"></v-img>
      </v-col>
    </v-row>
    <p>
      If you are unable to delete your account through the app, please contact
      us using the form below or email us at
      <a href="mailto:info@adaptsolutions.com">info@adaptsolutions.com</a> with
      the following details:
    </p>
    <ul>
      <li>Your Full Name</li>
      <li>Your Registered Email Address</li>
      <li>The Subject: "Delete My Account"</li>
    </ul>
    <p>
      Once we receive your request, we will process it within 7 business days.
    </p>
    <p>
      When you delete your account, your username and account information will
      be permanently removed from our servers. Any LINKs associated with your
      account will be deassociated. However, we may retain the LINK nicknames
      and dealer address information that you entered when adding the LINKs, for
      operational or record-keeping purposes.
    </p>
    <p>
      If you have any questions regarding the deletion process or retained data,
      feel free to contact us at
      <a href="https://adaptsolutions.com/about/contact/">Adapt Solutions Contact Informations</a>.
    </p>
    <router-link to="/">
      <v-btn style="display: block; margin: auto" color="primary">
        {{ $t("return_to_login") }}
      </v-btn>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "HowTo",
};
</script>
