<template>
  <div>
    <v-list three-line v-if="logs && logs.logs">
      <v-card>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Numéro de série</v-list-item-title>
            <v-list-item-subtitle>{{ logs.serialNumber }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Version logicielle</v-list-item-title>
            <v-list-item-subtitle>{{
              logs.linkSoftwareVersion
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Date dans la carte</v-list-item-title>
            <v-list-item-subtitle>{{ logs.actualDate }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Nombre de cycles</v-list-item-title>
            <v-list-item-subtitle>{{ logs.cycleCount }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Jour(s) avant maintenance</v-list-item-title>
            <v-list-item-subtitle>{{
              logs.daysBeforeMaintenance
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Compteur de maintenance reset</v-list-item-title>
            <v-list-item-subtitle>{{
              logs.maintenanceResetCount
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Compteur de eeprom Writes</v-list-item-title>
            <v-list-item-subtitle>{{
              logs.eepromWrites ? logs.eepromWrites : 0
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <br />
      <v-subheader
        >Historique
        <v-spacer />
        <v-btn fab color="white ml-5" @click="downloadCsv(logs)">
          <v-icon> mdi-cloud-download-outline </v-icon>
        </v-btn></v-subheader
      >

      <br />
      <template v-for="(item, index) in sortedLogs">
        <v-card
          v-if="!item.eventName.includes('EMPTY')"
          elevation="2"
          style="margin: 5px"
          :key="'log' + index"
          :class="{ 'error-log': isError(item.eventName) }"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon :color="isError(item.eventName) ? 'white' : 'black'">{{
                getLogIcon(item.eventName)
              }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                :style="{
                  color: isError(item.eventName) ? 'white' : 'black',
                }"
                v-text="item.logId"
              ></v-list-item-title>

              <v-list-item-subtitle
                :style="{
                  color: isError(item.eventName) ? 'white' : 'black',
                }"
                v-text="item.eventName + ' ::: ' + item.logPayload"
              ></v-list-item-subtitle>

              <v-list-item-subtitle
                :style="{
                  color: isError(item.eventName) ? 'white' : 'black',
                }"
                v-text="item.eventDate"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </template>
    </v-list>
  </div>
</template>

<script>
import { EventNames } from "@/utils/enums.js";
export default {
  name: "RemoteLogs",
  components: {},
  props: {
    logs: {
      type: Object,
      default: {},
    },
  },
  data: () => {
    return {};
  },
  methods: {
    isError(eventName) {
      switch (eventName) {
        case EventNames.ABUSIVE_USE:
        case EventNames.ENCODER_ERROR:
        case EventNames.FAULTY_POWER:
        case EventNames.FORKS_BLOCKED:
        case EventNames.MOTOR_OVERLOAD:
        case EventNames.OPEN_CIRCUIT:
        case EventNames.PIVOT_OBSTRUCTION:
        case EventNames.POWER_LOSS:
          return true;
        default:
          return false;
      }
    },
    getLogIcon(eventName) {
      if (this.isError(eventName)) {
        return "mdi-alert-outline";
      } else {
        return "mdi-information-outline";
      }
    },
    downloadCsv(logs) {
      const allLogs = this.sortedLogs.filter((a) => !a.eventName.includes("EMPTY"));
      // Step 1: Create CSV headers
      const headers = [
        "logId",
        "eventName",
        "eventDate",
        "logPayload",
        "logIndex",
      ];

      // Step 2: Map the log data into CSV rows
      var rows = allLogs
        .filter((a) => !a.eventName.includes("EMPTY"))
        .map((log) => [
          parseInt(log.logId),
          log.eventName,
          log.eventDate,
          log.logPayload,
          log.logIndex,
        ]);

      // Step 3: Combine headers and rows into a CSV string
      const csvContent = [
        headers.join(","),
        ...rows.map((row) => row.join(",")),
      ].join("\n");

      // Step 4: Create a blob and a download link
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      // Step 5: Create a link and trigger download
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `${logs.name}-${new Date().toDateString()}.csv`
      );
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
  },
  computed: {
    sortedLogs() {
      if (!this.logs.logs) return [];
      return [...this.logs.logs].sort(
        (a, b) => parseInt(b.logId) - parseInt(a.logId)
      ); // Sort and reverse here
    },
  },
};
</script>
<style scoped>
.error-log {
  background-color: red;
  color: white;
}
</style>
