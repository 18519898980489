import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
const io = require("socket.io-client");
import VueMask from "v-mask";

Vue.config.productionTip = false;

import axios from "axios";
import vuetify from "./plugins/vuetify";
import { VueReCaptcha } from "vue-recaptcha-v3";

const serverAddr = "https://mylink.adaptsolutions.com";
//const serverAddr = "http://192.168.1.231";

const base = axios.create({
  baseURL: serverAddr,
});

Vue.prototype.$axios = base;

store.$axios = base;

var socket = io.connect(serverAddr, { transports: ["websocket"] });

Vue.prototype.$socket = socket;

import api from "./api";
Vue.prototype.$api = api(base);

Vue.use(VueMask, {
  // (!) custom placeholders support requires registration as a plugin to
  placeholders: {
    "!": /[A-Z0-9]/, // cyrillic letter as a placeholder
  },
});

Vue.use(VueReCaptcha, { siteKey: "6Lc-gswpAAAAAHhtbqChfXGwiDWY4ERaDNQVvKDH" });

import i18n from "./i18n";

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
