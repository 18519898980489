export const SCREENSTATES = {
  REMOTE_REQUEST: 0,
  REMOTE_HOME: 1,
  REMOTE_BOOTLOAD: 2,
  REMOTE_PRESETS: 3,
  REMOTE_LOGS: 4,
  REMOTE_DIAGNOSTIC: 5,
  REMOTE_CONSOLE: 6,
};

export const REMOTESTATES = {
  REMOTE_START: 0,
  REMOTE_WAITING: 1,
  REMOTE_ACCEPTED: 2,
  REMOTE_CANCELLED: 3,
};

export const BOOTSTATE = {
  BOOT_START: 0,
  BOOT_SENDFILE: 1,
  BOOT_WAITING: 2,
  BOOT_PROGRESS: 3,
  BOOT_DONE: 4,
};

export const PRESETSTATE = {
  PRESET_START: 0,
  PRESET_SENDFILE: 1,
  PRESET_WAITING: 2,
  PRESET_PROGRESS: 3,
  PRESET_DONE: 4,
};

export const COMMANDS = {
  BACKWARD: "backward",
  FORWARD: "forward",
  DOWNWARD: "downward",
  UPWARD: "upward",
  INWARD: "inward",
  OUTWARD: "outward",
  STOP: "stop",
};

export const EventNames = {
  POWER_LOSS: "POWER_LOSS",
  ZERO: "ZERO",
  ENTER_MANUAL_MODE: "ENTER_MANUAL_MODE",
  ENTER_AUTOMATIC_MODE: "ENTER_AUTOMATIC_MODE",
  FORKS_BLOCKED: "FORKS_BLOCKED",
  PIVOT_OBSTRUCTION: "PIVOT_OBSTRUCTION",
  START_SUPPORT_SESSION: "START_SUPPORT_SESSION",
  END_SUPPORT_SESSION: "END_SUPPORT_SESSION",
  PRESET_LOADED: "PRESET_LOADED",
  PRESET_MODIFIED: "PRESET_MODIFIED",
  ENCODER_ERROR: "ENCODER_ERROR",
  MOTOR_OVERLOAD: "MOTOR_OVERLOAD",
  TIME_CORRECTED: "TIME_CORRECTED",
  OPEN_CIRCUIT: "OPEN_CIRCUIT",
  PARAMETER_CHANGED_TEXT_CONSOLE: "PARAMETER_CHANGED_TEXT_CONSOLE",
  ABUSIVE_USE: "ABUSIVE_USE",
  FAULTY_POWER: "FAULTY_POWER",
};

export const ErronousLogs = [
  EventNames.ABUSIVE_USE,
  EventNames.ENCODER_ERROR,
  EventNames.FAULTY_POWER,
  EventNames.FORKS_BLOCKED,
  EventNames.MOTOR_OVERLOAD,
  EventNames.OPEN_CIRCUIT,
  EventNames.PIVOT_OBSTRUCTION,
  EventNames.POWER_LOSS,
];
