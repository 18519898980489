<template>
    <v-container class="no-print">
      <Breadcrumbs :items="breadcrumbs" style="display: inline-block" />
      <div style="max-width: 800px; margin: auto">
        <h3>Password Solver</h3>
        <v-container>
          <v-form ref="form" v-model="valid" style="margin-top: 25px">
            <v-text-field
              rounded
              v-model="serial"
              label="Nom du fichier"
              validate-on-blur
              @input="handleInputChange"
              @focus="isFocused = true"
              @blur="isFocused = false"
              :rules="[ 
                (v) => !!v || 'Le nom du fichier est requis', 
                (v) => this.isValidSerial(v) || 'Format du fichier invalide' 
              ]"
              solo
            >
              <template v-slot:prepend-inner>
                <v-row justify="center" align="center">
                  <v-icon style="margin-left: 20px; margin-right: 10px">mdi-file-code-outline</v-icon>
                  <h5 style="margin-left: 10px; margin-right: 40px">{{ $t('firmware_file_name') }}</h5>
                </v-row>
              </template>
            </v-text-field>
          </v-form>
          <p>Password: {{ password }}</p>
        </v-container>
      </div>
    </v-container>
  </template>
  
  <script>
  import Breadcrumbs from "../components/Breadcrumbs.vue";
  
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }
  
  export default {
    name: "FindALink",
    components: { Breadcrumbs },
    data: () => ({
      breadcrumbs: [],
      valid: true,
      isFocused: false,
      serial: "",
      password: "", // Store the generated password
    }),
    methods: {
      handleInputChange: debounce(async function (newValue) {
        if (!this.isFocused) return;
  
        // Remove extensions
        const cleanSerial = this.removeExtension(newValue);
  
        if (this.isValidSerial(cleanSerial)) {
          this.password = this.generatePassword(cleanSerial);
        } else {
          this.password = "Invalid Serial Format";
        }
      }),
  
      isValidSerial(serial) {
        // Regex to check for valid formats U_X_LINK_V_X.X.X or T_X_...
        const regex = /^(U_\d+_LINK_V_\d+\.\d+\.\d+|T_\d+(_.*)?)$/;
        return regex.test(serial);
      },
  
      removeExtension(serial) {
        return serial.replace(/\.(cyacd2?|cyacd)$/i, "");
      },
  
      generatePassword(filename) {
        let password = "";
        const workString = this.getBaseFileName(filename);
  
        if (workString.startsWith("T")) {
          password += workString[0];
          password += this.getHardwareNumberAsString(workString);
          password += this.getVersionNumberAsString(workString, false);
        } else if (workString.startsWith("U")) {
          let passwordNumber = 0;
          const indexBeforeVersionNumber = workString.indexOf(".") - 1;
          const versionNumberAsInt = this.getVersionNumberAsInteger(workString);
          const arrayValue = [...workString].map((char) => char.charCodeAt(0));
          for (let i = 0; i < indexBeforeVersionNumber; i++) {
            passwordNumber += arrayValue[i];
          }
          passwordNumber += versionNumberAsInt;
          passwordNumber *= versionNumberAsInt;
          password = passwordNumber.toString();
        }
  
        return password;
      },
  
      getBaseFileName(str) {
        // Removes extensions like .cyacd2 or .cyacd
        return this.removeExtension(str);
      },
  
      getVersionNumberAsString(str, separatedByDots = true) {
        const workString = this.getBaseFileName(str);
        let resultString = workString.slice(workString.indexOf(".") - 1);
        if (!separatedByDots) {
          resultString = resultString.replace(/\./g, "");
        }
        return resultString;
      },
  
      getHardwareNumberAsString(str) {
        const workString = this.getBaseFileName(str);
        if (workString.includes("_")) {
          const indexFirstUnderscore = workString.indexOf("_");
          const indexSecondUnderscore = workString.indexOf("_", indexFirstUnderscore + 1);
          return workString.substring(indexFirstUnderscore + 1, indexSecondUnderscore);
        }
        return "";
      },
  
      getVersionNumberAsInteger(str) {
        return parseInt(this.getVersionNumberAsString(str, false), 10);
      },
    },
  };
  </script>
  
  <style scoped></style>
  