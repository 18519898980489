<template>
  <div>
    <v-app-bar id="app-bar" app flat color="white">
      <!--<v-icon>mdi-magnify</v-icon>
      <v-text-field
        v-model="searchText"
        flat
        solo
        hide-details
        :placeholder="$t('search')"
        @keydown.enter="searchForUsersOrLinks"
        @focus="menu = true"
        @click:append="
          (menu = false),
            (searchText = ''),
            (searchResults = { users: [], links: [] })
        "
        clearable
        style="vertical-align: middle; color: red"
      ></v-text-field> -->
      <!--
      <v-menu
        v-model="menu"
        id="menuSearch"
        absolute
        :position-x="60"
        :position-y="60"
        :close-on-content-click="false"
        :nudge-width="400"
      >
        <p>Links</p>
        <v-row v-if="searchResults.links.length > 0">
          <v-col
            v-for="link in searchResults.links"
            :key="link.serial"
            cols="12"
            style="padding: 2px"
          >
            <LinkBox :link="link" />
          </v-col>
        </v-row>
        <p style="margin-top: 15px">{{ $t("users") }}</p>
        <v-row v-if="searchResults.users.length > 0">
          <v-col
            v-for="user in searchResults.users"
            :key="user.id"
            cols="12"
            style="padding: 2px"
          >
            <UserBox :user="user" />
          </v-col>
        </v-row>
      </v-menu>
    -->
      <img
        src="/adapt_logo_black.svg"
        style="width: 68px; margin-right: 25px"
      />
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <LangButton style="margin-right: 20px"></LangButton>

        <v-menu
          attach="#app-bar"
          :close-on-content-click="false"
          :nudge-width="0"
          offset-y
          left
          style="border-radius: 18px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              style="
                background: #131e30;
                color: white;
                box-shadow: 2px 2px 5px rgb(19 30 48 / 25%);
              "
            >
              <h2 v-if="currentUser">
                {{ currentUser.email[0].toUpperCase() }}
              </h2>
            </v-btn>
          </template>

          <v-list style="min-width: 350px">
            <v-list-item v-if="currentUser">
              <v-list-item-icon>
                <v-icon
                  class="userbox-icon"
                  v-if="currentUser.roles.includes('admin')"
                  >mdi-shield-account</v-icon
                >
                <v-icon
                  class="userbox-icon"
                  v-else-if="currentUser.roles.includes('tech')"
                  >mdi-account-hard-hat</v-icon
                >
                <v-icon
                  class="userbox-icon"
                  v-else-if="currentUser.roles.includes('installer')"
                  >mdi-tools</v-icon
                >
                <v-icon v-else>mdi-account</v-icon></v-list-item-icon
              >
              <v-list-item-content>{{
                currentUser.roles.includes("admin")
                  ? $t("admin")
                  : currentUser.roles.includes("tech")
                  ? $t("tech")
                  : currentUser.roles.includes("installer")
                  ? $t("installer")
                  : "User"
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="currentUser">
              <v-list-item-icon><v-icon>mdi-email</v-icon></v-list-item-icon>
              <v-list-item-content>{{ currentUser.email }}</v-list-item-content>
            </v-list-item>
            <v-list-item @click="logOut">
              <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
              <v-list-item-content>{{ $t("logout") }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer
      app
      dark
      mini-variant
      permanent
      expand-on-hover
      color="primary"
      style="border-radius: 0px 10px 10px 0px"
      :elevation="6"
    >
      <v-layout column fill-height>
        <v-list nav dense>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-sort-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <img
                src="/adapt_logo_white.svg"
                style="width: 68px; margin-right: 25px"
            /></v-list-item-title>
          </v-list-item>

          <v-list-item
            link
            to="/findmylink"
            :class="{
              'v-list-item--active': $route.path.startsWith('/findmylink'),
            }"
          >
            <v-list-item-icon>
              <v-icon>mdi-tag-search</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("find_a_link") }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            link
            v-if="isAdmin || isTech"
            to="/links"
            :class="{ 'v-list-item--active': $route.path.startsWith('/links') }"
          >
            <v-list-item-icon>
              <v-icon>mdi-car-seat</v-icon>
            </v-list-item-icon>
            <v-list-item-title>LINKs</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="isAdmin || isTech"
            link
            to="/users"
            :class="{ 'v-list-item--active': $route.path.startsWith('/user') }"
          >
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("users") }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="isAdmin || isTech"
            link
            to="/approbations"
            :class="{
              'v-list-item--active': $route.path.startsWith('/approbations'),
            }"
          >
            <v-list-item-icon>
              <v-icon>mdi-badge-account-alert-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Approbations</v-list-item-title>
            <v-list-item-action>
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 20px;
                  height: 20px;
                  background: red;
                  border-radius: 50%;
                  color: white;
                  font-size: 10px;
                "
              >
                {{ this.approbations.length }}
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-spacer></v-spacer>
        <v-divider></v-divider>
        <v-list nav dense>
          <v-list-item
            link
            v-if="isAdmin || isTech"
            to="/solver"
            :class="{ 'v-list-item--active': $route.path.startsWith('/solver') }"
          >
            <v-list-item-icon>
              <v-icon>mdi-pipe-wrench</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('solver') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            disabled
            link
            to="/settings"
            :class="{
              'v-list-item--active': $route.path.startsWith('/settings'),
            }"
          >
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("settings") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-layout>
    </v-navigation-drawer>

    <router-view />
  </div>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";
import { mapState, mapGetters, mapMutations } from "vuex";

import LinkBox from "./components/LinkBox.vue";
import UserBox from "./components/UserBox.vue";
import approbations from "./api/approbations";
import LangButton from "./components/LangButton.vue";

export default {
  components: { LinkBox, UserBox, LangButton },
  computed: {
    ...mapGetters(["isAdmin", "isTech"]),
    ...mapState({
      currentUser: (state) => state.currentUser,
      users: (state) => state.users,
      links: (state) => state.links,
      approbations: (state) => state.approbations,
    }),
  },
  data: () => {
    return {
      drawer: true,
      searchText: "",
      searchResults: { users: [], links: [] },
      menu: false,
    };
  },
  methods: {
    ...mapMutations({
      setApprobations: "setApprobations",
    }),
    async logOut() {
      console.log("logOut");

      const auth = getAuth();
      try {
        await signOut(auth);

        this.$router.push("/login");
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    async searchForUsersOrLinks() {
      if (this.searchText !== "") {
        this.searchResults = {
          users: this.isAdmin()
            ? this.users.filter(
                (u) =>
                  u.displayName
                    .toLowerCase()
                    .includes(this.searchText.toLowerCase()) ||
                  u.email.toLowerCase().includes(this.searchText.toLowerCase())
              )
            : [],
          links: this.links.filter(
            (l) =>
              l.serial.toLowerCase().includes(this.searchText.toLowerCase()) ||
              l.manufacturer
                .toLowerCase()
                .includes(this.searchText.toLowerCase())
          ),
        };
        this.menu = true;
      }
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    async getApprobations() {
      try {
        const data = await this.$api.approbations.getApprobations();
        this.setApprobations(data);
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    console.log("MasterPage mounted");
    if (this.currentUser && this.currentUser.roles.includes("admin")) {
      this.getApprobations();
    }
  },
};
</script>

<style>
.v-toolbar__title {
  overflow: visible;
}
.v-sheet.v-card {
  border-radius: 10px !important;
}
.v-list--nav .v-list-item,
.v-list--nav .v-list-item:before {
  border-radius: 20px !important;
}

.v-menu__content {
  background: white;
  overflow-y: scroll;
  max-height: 600px;
  width: 350px;
}

::v-deep #menuSearch {
  position: fixed;
  left: 100px;
  top: 75px;
}

.v-list-item--active {
  background-color: #f4f7fd !important;
  color: #131e30 !important;
}

.v-menu__content {
  border-radius: 18px;
}
</style>
